<template>
  <cui-login />
</template>
<script>
import CuiLogin from '@/components/cleanui/system/Auth/Login'
export default {
  components: {
    CuiLogin,
  },
}
</script>
