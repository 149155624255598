var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.containerlogin},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"leftContent col-4 pr-0"},[(!_vm.settings.isMobileView)?_c('div',{class:_vm.$style.kolomkiri},[_c('div',{class:_vm.$style.kiridalam},[_c('img',{staticClass:"w-25 pt-4 pl-4",attrs:{"src":require("@/assets/PowerBiz/Logo-White.png")}}),(_vm.loading)?_c('div',{staticClass:"loading ml-3"},[_c('img',{staticClass:"w-25 ml-4",attrs:{"src":"https://ik.imagekit.io/winoto/adm-saas/loading-adm-saas_yJ6thfkHs9wS.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1645567288157"}})]):_vm._e(),_c('div',{staticClass:"mx-4"},[_c('div',{class:_vm.$style.titleLogo},[_vm._m(0)]),_c('div',{class:_vm.$style.textLogo},[_vm._v(" We offer an end-to-end solution and services to empower your brand, transform your business, and bring your products to global market with Omni-Channel Technology Platform ")]),_c('a-button',{staticClass:"text-center w-60 my-5",attrs:{"type":"danger","size":"large","html-type":"submit"}},[_c('strong',[_vm._v(" Schedule a demo ")])])],1)])]):_vm._e()]),_c('div',{staticClass:"col-8 d-flex justify-content-between flex-column",staticStyle:{"background-color":"#ffffff"}},[_c('div',{staticClass:"logo"}),_c('div',{staticClass:"d-flex justify-content-end align-items-center m-4",attrs:{"id":"topMenu"}},[_c('div',{staticClass:"pbz-font text-center",staticStyle:{"color":"#999999"}},[_vm._v(" "+_vm._s(_vm.$t('auth.notRegister'))+" ")]),_c('div',[_c('a-button',{staticClass:"pbz-font body-md-bold text-center mx-3",class:{
              [_vm.$style.button__submit__login__page]: true,
            },attrs:{"size":"default","html-type":"submit"}},[_c('router-link',{attrs:{"to":'/auth/register'}},[_c('span',{style:({ color: '#000000' })},[_vm._v(" "+_vm._s(_vm.$t('auth.signupNow'))+" ")])])],1)],1),_c('SetLang',{staticClass:"setLang"})],1),_c('div',{staticClass:"card-borderless col-6 mx-auto",staticStyle:{"margin-top":"13%"},attrs:{"id":"formInput"}},[_c('div',{class:_vm.$style.innerForm},[_c('div',{staticClass:"pbz-font-h7 body-md-bold",style:({ color: '#000000' })},[_c('strong',[_vm._v(_vm._s(_vm.$t('auth.login')))])]),_c('div',{staticClass:"mt-4"},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-model-item',{class:{
                  [_vm.$style.container__form]: true,
                },attrs:{"prop":"user_login","rules":[{
                  required: true,
                  message: _vm.$t('auth.invalidEmailPhoneNumber'),
                }]}},[_c('a-input',{attrs:{"size":"large","placeholder":_vm.$t('auth.emailPhoneLabel')},model:{value:(_vm.form.user_login),callback:function ($$v) {_vm.$set(_vm.form, "user_login", $$v)},expression:"form.user_login"}})],1),_c('a-form-model-item',{class:{
                  [_vm.$style.container__form]: true,
                },attrs:{"prop":"password","rules":[{
                  required: true,
                  message: _vm.$t('auth.invalidPassword'),
                }]}},[_c('a-input-password',{attrs:{"size":"large","placeholder":_vm.$t('auth.password')},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('div',{staticClass:"pb-3",style:({ color: '#F2994A' })},[_c('router-link',{attrs:{"to":'/auth/forgot-password'}},[_c('span',{style:({ color: '#2B67F6' })},[_vm._v(" "+_vm._s(_vm.$t('auth.forgetPassword'))+" ")])])],1),_c('a-button',{staticClass:"pbz-font body-md-bold text-center w-100 mt-3",class:{
                  [_vm.$style.button__submit]: true,
                },attrs:{"size":"large","loading":_vm.loading_button,"html-type":"submit"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('auth.login'))+" ")])])],1)],1),_c('div',{staticClass:"anotherLogin"},[_c('div',{staticClass:"pt-4 pb-1 text-center"},[_c('p',{staticClass:"anotherSign pbz-font body-sm-medium"},[_vm._v(" "+_vm._s(_vm.$t('auth.anotherSign'))+" ")])]),_c('div',{staticClass:"mt-1 mb-2",class:{
                [_vm.$style.anotherRegisterCard]: true,
              }},[_c('img',{staticClass:"mx-1 anotherLogin_logo",attrs:{"src":require("@/assets/PowerBiz/google-logo.png")}}),_c('div',{staticClass:"mx-4 pbz-font body-md-medium anotherLogin_desc",staticStyle:{"color":"#202759"}},[_vm._v(" "+_vm._s(_vm.$t('auth.signInWithGoogle'))+" ")])]),_c('div',{staticClass:"mt-1 mb-2",class:{
                [_vm.$style.anotherRegisterCard]: true,
              }},[_c('img',{staticClass:"mx-1 anotherLogin_logo",attrs:{"src":require("@/assets/PowerBiz/facebook-logo.png")}}),_c('div',{staticClass:"mx-4 pbz-font body-md-medium anotherLogin_desc",staticStyle:{"color":"#202759"}},[_vm._v(" "+_vm._s(_vm.$t('auth.signInWithFacebook'))+" ")])])])]),_vm._m(1)]),_c('div',{staticClass:"footer text-center"},[_c('Footer')],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"pbz-font-h5"},[_vm._v(" Empowering "),_c('br'),_vm._v(" Your Brand ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center pt-2"},[_vm._v(" View our "),_c('a',{attrs:{"href":"#"}},[_c('b',[_vm._v("Terms")])]),_vm._v(" and "),_c('a',{attrs:{"href":"#"}},[_c('b',[_vm._v("Privacy Policy")])])])
}]

export { render, staticRenderFns }