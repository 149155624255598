<template>
  <div class="alih-bahasa">
    <a-select
    default-value="Indonesia"
      style="width: 120px; height: 40px; color:blue; border: none; outline-color: white;"
      size="large"
      @change="handleChange"
      @click="(e) => e.preventDefault()"
      class="pbz-font text-center"
    >
      <a-select-option value="id-ID">
        <a href="javascript:void(1);" @click="setLang('id-ID')"><p class="pbz-font body-sm-regular" style="color:blue;"> Indonesia </p></a>
      </a-select-option>

      <a-select-option value="en-US">
        <a href="javascript:void(1);" @click="setLang('en-US')"><p class="pbz-font body-sm-regular" style="color:blue;"> English </p></a>
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  name: 'SetLang',
  components: {},
  methods: {
    handleChange(value) {
      // console.log(`selected language ${value}`)
      this.$store.commit('settings/CHANGE_SETTING', {
        setting: 'locale',
        value,
      })
    },

    setLang(localeAntd) {
      // ubah nilai state bahasa
      this.$store.commit('settings/CHANGE_SETTING', localeAntd)
      // set ulang router dgn menambahkan query string lang berdasarkan bahasa yang dipilih
      this.$router.push({
        path: `${this.$router.currentRoute.path}?lang=${localeAntd}`,
      })
    },
  },
}
</script>
<style scoped>
.a-select {
  border: none;
  outline-color: white;
}

</style>
