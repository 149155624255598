<template>
  <div :class="$style.containerlogin">
    <div class="row">
      <!-- left content -->
      <div class="leftContent col-4 pr-0">
        <div v-if="!settings.isMobileView" :class="$style.kolomkiri">
          <div :class="$style.kiridalam">
            <!--logo-->
            <img
              class="w-25 pt-4 pl-4"
              src="@/assets/PowerBiz/Logo-White.png"
            />
            <div v-if="loading" class="loading ml-3">
              <img class="w-25 ml-4" src="https://ik.imagekit.io/winoto/adm-saas/loading-adm-saas_yJ6thfkHs9wS.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1645567288157" />
            </div>
            <div class="mx-4">
              <div :class="$style.titleLogo">
                <p class="pbz-font-h5">
                  Empowering <br />
                  Your Brand
                </p>
              </div>
              <div :class="$style.textLogo">
                We offer an end-to-end solution and services to empower your
                brand, transform your business, and bring your products to
                global market with Omni-Channel Technology Platform
              </div>
              <a-button
                type="danger"
                size="large"
                class="text-center w-60 my-5"
                html-type="submit"
              >
                <strong> Schedule a demo </strong>
              </a-button>
            </div>
          </div>
        </div>
      </div>

      <!-- right content -->
      <div
        class="col-8 d-flex justify-content-between flex-column"
        style="background-color: #ffffff; !important"
      >
        <div class="logo" />
        <!-- top -->
        <div id="topMenu" class="d-flex justify-content-end align-items-center m-4">
          <div class="pbz-font text-center" style="color: #999999">
            {{ $t('auth.notRegister') }}
          </div>
          <div>
            <a-button
              size="default"
              html-type="submit"
              class="pbz-font body-md-bold text-center mx-3"
              :class="{
                [$style.button__submit__login__page]: true,
              }"
            >
              <router-link :to="'/auth/register'">
                <span :style="{ color: '#000000' }">
                  {{ $t('auth.signupNow') }}
                </span>
              </router-link>
            </a-button>
          </div>
          <!-- alih bahasa -->
          <SetLang class="setLang" />
        </div>

        <!-- form  -->
        <div id="formInput" class="card-borderless col-6 mx-auto" style="margin-top:13%;">
          <div :class="$style.innerForm">
            <div class="pbz-font-h7 body-md-bold" :style="{ color: '#000000' }">
              <strong>{{ $t('auth.login') }}</strong>
            </div>

            <div class="mt-4">
              <a-form-model ref="ruleForm" :model="form" @submit.prevent="handleSubmit">
                <a-form-model-item
                  prop="user_login"
                  :class="{
                    [$style.container__form]: true,
                  }"
                  :rules="[{
                    required: true,
                    message: $t('auth.invalidEmailPhoneNumber'),
                  }]"
                >
                  <a-input
                    v-model="form.user_login"
                    size="large"
                    :placeholder="$t('auth.emailPhoneLabel')"
                  />
                </a-form-model-item>
                <a-form-model-item
                  prop="password"
                  :class="{
                    [$style.container__form]: true,
                  }"
                  :rules="[{
                    required: true,
                    message: $t('auth.invalidPassword'),
                  }]"
                >
                  <a-input-password
                    v-model="form.password"
                    size="large"
                    :placeholder="$t('auth.password')"
                  />
                </a-form-model-item>
                <div class="pb-3" :style="{ color: '#F2994A' }">
                  <router-link :to="'/auth/forgot-password'">
                    <span :style="{ color: '#2B67F6' }">
                      {{ $t('auth.forgetPassword') }}
                    </span>
                  </router-link>
                </div>
                <a-button
                  size="large"
                  class="pbz-font body-md-bold text-center w-100 mt-3"
                  :class="{
                    [$style.button__submit]: true,
                  }"
                  :loading="loading_button"
                  html-type="submit"
                >
                  <strong> {{ $t('auth.login') }} </strong>
                </a-button>
              </a-form-model>
            </div>
            <div class="anotherLogin">
              <div class="pt-4 pb-1 text-center">
                <p class="anotherSign pbz-font body-sm-medium">
                  {{ $t('auth.anotherSign') }}
                </p>
              </div>

              <div
                :class="{
                  [$style.anotherRegisterCard]: true,
                }"
                class="mt-1 mb-2"
              >
                <img class="mx-1 anotherLogin_logo" src="@/assets/PowerBiz/google-logo.png" />
                <div class="mx-4 pbz-font body-md-medium anotherLogin_desc" style="color: #202759">
                  {{ $t('auth.signInWithGoogle') }}
                </div>
              </div>

              <div
                :class="{
                  [$style.anotherRegisterCard]: true,
                }"
                class="mt-1 mb-2"
              >
                <img class="mx-1 anotherLogin_logo" src="@/assets/PowerBiz/facebook-logo.png" />
                <div class="mx-4 pbz-font body-md-medium anotherLogin_desc" style="color: #202759">
                  {{ $t('auth.signInWithFacebook') }}
                </div>
              </div>
            </div>
          </div>
          <p class="text-center pt-2">
            View our <a href="#"><b>Terms</b></a> and
            <a href="#"><b>Privacy Policy</b></a>
          </p>
        </div>

        <!-- bottom -->
        <div class="footer text-center">
          <Footer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
import SetLang from '@/components/Lang'
import Footer from '@/components/cleanui/layout/Footer'
// import Axios from 'axios'

export default {
  name: 'CuiLogin',
  components: { SetLang, Footer },
  data: function () {
    return {
      loading: false,
      form: {
        user_login: '',
        password: '',
      },
      dataPhoneError: {
        field: null,
        error_message: [],
      },
      passwordError: {
        field: null,
        error_message: [],
      },
    }
  },

  computed: {
    ...mapState(['user', 'settings', 'app']),
    ...mapGetters({ loginStatusCode: 'user/loginStatusCode' }),
    loading_button() {
      return this.$store.state.user.loading
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.loading = true
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$store
            .dispatch('user/SIGNINV2', this.form)
            .then(() => {
              const redirectUrl = this.$route.query.redirect || '/dashboard'
              this.$router.push({ path: redirectUrl })
              this.loading = false
            })
            .catch((err) => {
              this.$notification.error({
                message: this.$t('auth.loginFailed'),
                description: err.response?.data?.message || err.message,
              })
              this.loading = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
@import '@/components/cleanui/system/Auth/Login/style.module.scss';
</style>
<style scoped>
@media only screen and (max-width: 770px) {
  .anotherLogin_logo {
   display: inline-block;
  }
  .anotherLogin_desc {
   display: none;
  }
}
@media only screen and (max-width: 426px) {
  .col-8 {
    max-width: 99%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .logo {
    background: url("https://ik.imagekit.io/powerbiz/img/admin-saas/Logo.png?updatedAt=1631759657482") no-repeat;
    background-position: center;
    background-size: 120px 60px;
    height: 90px;
  }
  #topMenu {
    display: flex;
    flex-direction: column;
    margin: 3px;
  }
  .leftContent {
   display: none;
  }
  #formInput {
    max-width: 90%;
    margin: 0;
    padding: 0;
  }
  .setLang {
   margin-top: 10px;
  }
  .anotherLogin {
    display: none;
  }
  .footer {
    display: none;
  }
}
</style>
